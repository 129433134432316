export default class PortalIdle {

  startWatch(idleDurationSecs, libName, logoutUrl) {
    const idleDuration = idleDurationSecs * 1000;
    console.info("Autologout set for " + idleDurationSecs + "s; target: " + logoutUrl);

    let idleTimeout;

    const resetIdleTimeout = () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }

      idleTimeout = setTimeout(() => {
        DotNet.invokeMethodAsync(libName, "Logout", logoutUrl);
      }, idleDuration);
    };

    resetIdleTimeout();

    ["click", "touchstart", "mousemove"].forEach((evt) =>
      document.body.addEventListener(evt, resetIdleTimeout, false)
    );
  }

}
