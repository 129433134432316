import VouchersOverview from './Components/vouchers-overview.js';
import PortalIdle from './Components/portal-idle.js';
import FileDownload from './Components/file-download.js';
import PopupMessage from './Components/popup-message.js';
import Cookies from './Components/cookies.js';
import PortalModal from './Components/portal-modal.js';
import VouchersReset from './Components/voucher-reset.js';
import LoadingAnimation from './Components/loading-animation.js';
import MainNav from './Components/main-nav.js';
import Login from './Components/login.js';
import CardsList from './Components/cards-list.js';


window.components = {};

const init = {
	VouchersOverview,
	PortalIdle,
	FileDownload,
	PopupMessage,
	Cookies,
	PortalModal,
	VouchersReset,
	LoadingAnimation,
	MainNav,
	Login,
	CardsList
};

for(var i in init) {
	const component = new init[i]();
	window.components[i] = component;
}

document.dispatchEvent(new Event('components_init'));