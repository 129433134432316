export default class Cookies {
  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  
  getCookie(name) {
    if (name !== undefined) {
      let nameEQ = name + "=";
      let allCookies = document.cookie.split(";");

      if (allCookies !== undefined) {
        for (let i = 0; i < allCookies.length; i++) {
          let cookie = allCookies[i].trim();

          if (cookie.indexOf(nameEQ) != -1) {
            var cookieValue = cookie.substring(nameEQ.length, cookie.length);
            return cookieValue;
          }
        }
      }
    }
    return null;
  }

  setCookieObj(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    var cookie = [name, "=", JSON.stringify(value), expires, "; path=/;"].join(
      ""
    );
    document.cookie = cookie;
  }

  getCookieObj(name) {
    var result = document.cookie.match(new RegExp(name + "=([^;]+)"));
    result && (result = JSON.parse(result[1]));
    return result;
  }
}
