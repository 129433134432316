export default class VouchersReset {
  constructor() {
    document.body.onkeyup = this.key_event;
  }

  key_event(e) {
    if (e.keyCode === 27) {
      DotNet.invokeMethodAsync("TEEG.Blazor", "ResetForm");
      const i = document.getElementById("voucher-search__input");
      if (i) i.focus();
    }
  }

  focusCursor() {
      const input = document.getElementById("voucher-search__input");
      if (input)
        input.focus();
  }
}
