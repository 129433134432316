import gsap from "gsap";

export default class VouchersOverview {
  animate() {
    const bar = document.querySelector(".voucher-guest__bar");

      if (bar) {
      const inner = bar.querySelector("div");
      const middle = document.querySelector(
        ".voucher-guest__meter-mark--middle"
      );

      gsap.to(inner, {
        width: `${bar.dataset.value}%`,
        duration: 1,
      });

      gsap.to(middle, {
        left: `${Math.min(bar.dataset.value, "88")}%`,
        duration: 1,
      });

      setTimeout(() => {
        const middleLeft = middle.style.left;
        if (middleLeft === "100%") {
          middle.style.display = "none";
        }
      }, 2000);
    }
  }

  copyToClipboard(str) {
    navigator.clipboard.writeText(str);
  }
}
